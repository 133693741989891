import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import ModalBoxInline from '../../../../components/ModalBoxInline';
import styles from './styles.module.css';

class ProductsLayout extends React.Component {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';
    const full = props.section.data[index].full === true ? 'Full' : '';

    this.state = {
      section: props.section,
      full,
      align,
      showModal: false,
      modalIndex: 0,
    };

    this.createContent = this.createContent.bind(this);
    this.createModal = this.createModal.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

  handleCardClick(index) {

    this.setState({

      showModal: true,
      modalIndex: index,

    });

  }

  handleCloseModal() {

    this.setState({

      showModal: false,

    });

  }

  createContent(item, index, cols) {

    const themeDefaultStyle = {
      fontFamily: this.props.themeData.typography.default.name,
      fontWeight: this.props.themeData.typography.default.weight,
      lineHeight: this.props.themeData.typography.default.lineHeight,
      letterSpacing: this.props.themeData.typography.default.letterSpacing,
      textTransform: this.props.themeData.typography.default.textTransform,
    };

    const themeHeadingStyle = {
      fontFamily: this.props.themeData.typography.heading.name,
      fontWeight: this.props.themeData.typography.heading.weight,
      lineHeight: this.props.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.themeData.typography.heading.letterSpacing,
      textTransform: this.props.themeData.typography.heading.textTransform,
    };

    const colorWhite = {

      color: 'white',

    };

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === '' &&
        item.styles.backgroundColor.gradient.from === ''
      ) {

        backgroundColor = { solid: '#ffffff' };

      }

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
      );

    }

    const align = `align${item.align}`;
    const design = this.props.layout === 'left' ? 'Left' : this.props.layout === 'design2' ? 'Design2' : '';
    const subtitle2 = `Subtitle2${this.props.themeData.typography.heading.fontSize}`;
    const body = `Body${this.props.themeData.typography.default.fontSize}`;

    let text;

    if (this.props.layout === 'design2') {

      text = (
        <React.Fragment>
          {
            item.data[1].active &&
            (
              <div
                id={`${this.state.section._id}_Heading_${index}_section`}
                className={`${styles.productsComponentHeading} ${styles[subtitle2]} ${styles.exceptionWeight}`}
                style={themeHeadingStyle}
              >
                {HTMLParser(`<span>${item.data[1].data}</span>`)}
              </div>
            )
          }
          {
            item.data[2].active &&
            (
              <div
                id={`${this.state.section._id}_Subtitle_${index}_section`}
                className={`${styles.productsComponentSubtitle} ${styles[body]} ${styles.exceptionWeight}`}
                style={themeDefaultStyle}
              >
                {HTMLParser(`<span>${item.data[2].data}</span>`)}
              </div>
            )
          }
          {
            item.data[3].active &&
            (
              <div
                id={`${this.state.section._id}_Paragraph_${index}_section`}
                className={`${styles.productsComponentParagraph} ${styles[body]}`}
                style={themeDefaultStyle}
              >
                {HTMLParser(`<p>${item.data[3].data}</p>`)}
              </div>
            )
          }
        </React.Fragment>
      );

      return (
        <div
          key={`${this.state.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights} ${styles.bottomMargin}`}
        >
          <div
            id={`${this.state.section._id}_Component_${index}_section`}
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${design}`]}`}
            style={style}
          >
            {
              item.data[0].active &&
              (
                <div className={`productsImageWrapper${design}`}>
                  <SectionMedia
                    id={this.state.section.data[index].data[0].data.id}
                    mediaType={(this.state.section.data[index].data[0].data.icon) ? 'ICON' : 'IMAGE'}
                    wrapperStyle={''}
                    elementStyle={'productsImageElementDesign2'}
                    align={item.align}
                    componentIndex={index}
                    elementIndex={0}
                    src={this.state.section.data[index].data[0].data.CDNLink ?
                      this.state.section.data[index].data[0].data.CDNLink :
                      `${this.state.section.data[index].data[0].data.src}`}
                    alt={this.state.section.data[index].data[0].data.alt}
                    data={this.state.section.data[index].data[0].data}
                    images={this.props.images}
                  />
                </div>
              )
            }
            <div
              className={styles.productsTextBlockDesign2Wrapper}
            >
              <div
                className={styles.productsTextBlockDesign2}
              >
                {text}
              </div>
            </div>
          </div>
        </div>
      );

    } else if (this.props.layout === 'left') {

      text = (
        <React.Fragment>
          <div
            className={styles.productsTextWrapper}
          >
            {
              item.data[1].active &&
              (
                <div
                  id={`${this.state.section._id}_Heading_${index}_section`}
                  className={`${styles.productsComponentHeading} ${styles[subtitle2]} ${styles.exceptionWeight} ${styles[align]}`}
                  style={themeHeadingStyle}
                >
                  <h4 style={colorWhite}>
                    {HTMLParser(item.data[1].data)}
                  </h4>
                </div>
              )
            }
            {
              item.data[2].active &&
              (
                <div
                  id={`${this.state.section._id}_Subtitle_${index}_section`}
                  className={`${styles.productsComponentSubtitle} ${styles[body]} ${styles.exceptionWeight} ${styles[align]}`}
                  style={themeDefaultStyle}
                >
                  <p style={colorWhite}>
                    {HTMLParser(item.data[2].data)}
                  </p>
                </div>
              )
            }
          </div>
        </React.Fragment>
      );

      return (
        <div
          key={`${this.state.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          {this.createModal(index, item)}
          <div
            id={`${this.state.section._id}_Component_${index}_section`}
            data-layout="Products"
            tabIndex={0}
            className={`${styles.productsComponentsWrapperLeft} ${styles.productsCardWrapper}`}
            style={style}
            onClick={() => this.handleCardClick(index)}
          >
            {
              item.data[0].active &&
              (
                <div className={`${styles[`productsImageWrapper${design}`]} ${styles.productsCard}`}>
                  <SectionMedia
                    id={this.state.section.data[index].data[0].data.id}
                    mediaType={(this.state.section.data[index].data[0].data.icon) ? 'ICON' : 'IMAGE'}
                    wrapperStyle={''}
                    elementStyle={'productsImageElement'}
                    align={item.align}
                    componentIndex={index}
                    elementIndex={0}
                    src={this.state.section.data[index].data[0].data.CDNLink ?
                      this.state.section.data[index].data[0].data.CDNLink :
                      `${this.state.section.data[index].data[0].data.src}`}
                    alt={this.state.section.data[index].data[0].data.alt}
                    data={this.state.section.data[index].data[0].data}
                    images={this.props.images}
                  />
                </div>
              )
            }
            {text}
          </div>
        </div>
      );

    }

    return null;

  }

  createModal(index, item) {

    if (item.active) {

      const themeDefaultStyle = {
        fontFamily: this.props.themeData.typography.default.name,
        fontWeight: this.props.themeData.typography.default.weight,
        lineHeight: this.props.themeData.typography.default.lineHeight,
        letterSpacing: this.props.themeData.typography.default.letterSpacing,
        textTransform: this.props.themeData.typography.default.textTransform,
      };

      const themeHeadingStyle = {
        fontFamily: this.props.themeData.typography.heading.name,
        fontWeight: this.props.themeData.typography.heading.weight,
        lineHeight: this.props.themeData.typography.heading.lineHeight,
        letterSpacing: this.props.themeData.typography.heading.letterSpacing,
        textTransform: this.props.themeData.typography.heading.textTransform,
      };

      const align = `align${item.align}`;

      const textElements = (
        <React.Fragment>
          {
            item.data[1].active &&
            (
              <div
                id={`${this.state.section._id}_Heading_${index}_section`}
                className={`${styles.productsComponentHeading} ${styles[`Title3${this.props.themeData.typography.heading.fontSize}`]} ${styles.exceptionWeight} ${styles[align]}`}
                style={themeHeadingStyle}
              >
                {HTMLParser(`<h3>${item.data[1].data}</h3>`)}
              </div>
            )
          }
          {
            item.data[3].active &&
            (
              <div
                id={`${this.state.section._id}_Paragraph_${index}_section`}
                className={`${styles.productsComponentModalParagraph} ${styles[`Body${this.props.themeData.typography.default.fontSize}`]} ${styles[align]}`}
                style={themeDefaultStyle}
              >
                {HTMLParser(`<p>${item.data[3].data}</p>`)}
              </div>
            )
          }
          {
            item.data[2].active &&
            (
              <div
                id={`${this.state.section._id}_Subtitle_${index}_section`}
                className={`${styles.productsComponentSubtitle} ${styles[`Body${this.props.themeData.typography.default.fontSize}`]} ${styles.exceptionWeight} ${styles[align]}`}
                style={themeDefaultStyle}
              >
                {HTMLParser(`<h4>${item.data[2].data}</h4>`)}
              </div>
            )
          }
        </React.Fragment>
      );

      const data = (
        <div className={styles.productsModalWrapper}>
          {
            item.data[0].active
            && (
              <div className={styles.modalLeftColumn}>
                <SectionMedia
                  id={item.data[0].data.id}
                  mediaType={(item.data[0].data.icon) ? 'ICON' : 'IMAGE'}
                  elementStyle={'productsImageModal'}
                  componentIndex={index}
                  elementIndex={0}
                  src={item.data[0].data.CDNLink ?
                    item.data[0].data.CDNLink :
                    `${item.data[0].data.src}`}
                  alt={item.data[0].data.alt}
                  data={item.data[0].data}
                  images={this.props.images}
                />
              </div>
            )
          }
          <div className={styles.modalRightColumn} style={!item.data[0].active ? { width: '100%' } : undefined}>
            <div className={styles.modalCloseButton}>
              <i
                className={`entypo icon-cancel ${styles['modalCloseIcon']}`}
                onClick={this.handleCloseModal}
              />
            </div>
            <div className={styles.modalTextWrapper}>
              {textElements}
            </div>
          </div>
        </div>
      );

      if (this.state.modalIndex === index) {

        return (
          <ModalBoxInline
            isOpen={this.state.showModal}
            id="productsModal"
            onCloseClick={this.handleCloseModal}
            data={data}
            noBox
            noHeaderOnMobile
          />
        );

      }

    }

    return null;

  }

  render() {

    const themeHeadingStyle = {
      fontFamily: this.props.themeData.typography.heading.name,
      fontWeight: this.props.themeData.typography.heading.weight,
      lineHeight: this.props.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.themeData.typography.heading.letterSpacing,
      textTransform: this.props.themeData.typography.heading.textTransform,
    };

    const themeDefaultStyle = {
      fontFamily: this.props.themeData.typography.default.name,
      fontWeight: this.props.themeData.typography.default.weight,
      lineHeight: this.props.themeData.typography.default.lineHeight,
      letterSpacing: this.props.themeData.typography.default.letterSpacing,
    };

    const color3 = {
      color: this.props.themeData.colors[3],
    };

    const cols = 'col-12 col-sm-8 offset-sm-2 offset-md-0 col-md-4';

    const activeComponents = [];
    this.state.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        const content = this.createContent(item, index, cols);
        activeComponents.push(content);

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;
    let open;
    if (this.state.showModal) open = { zIndex: 999 };

    return (
      <div className={`container ${styles[`productsContainer${this.state.full}`]}`} style={open}>
        <div className={`row ${styles[alignStyle]}`}>
          <div
            id={`${this.state.section._id}_Title_0_section`}
            className={`col-12 ${styles.productsMainHeader} ${styles[`Title2${this.props.themeData.typography.heading.fontSize}`]}`}
            style={{ ...themeHeadingStyle, ...color3 }}
          >
            {
              this.state.section.data[0].active
              && (
                <React.Fragment>
                  {
                    HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
                  }
                </React.Fragment>
              )
            }
          </div>
          <div
            id={`${this.state.section._id}_Subtitle_section`}
            className={`col-12 ${styles.productsMainSubtitle} ${styles[`Subtitle2${this.props.themeData.typography.default.fontSize}`]}`}
            style={{ ...themeDefaultStyle, ...color3 }}
          >
            {
              this.state.section.data[1].active
              && (
                <React.Fragment>
                  {
                    HTMLParser(`<p>${this.state.section.data[1].data}</p>`)
                  }
                </React.Fragment>
              )
            }
          </div>
          { activeComponents }
        </div>
      </div>
    );

  }

}

ProductsLayout.propTypes = {
  mobileView: PropTypes.bool,
  page: PropTypes.string,
  section: PropTypes.shape({}),
  align: PropTypes.string,
  full: PropTypes.string,
  active: PropTypes.string,
  onTextClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onComponentBlurAlt: PropTypes.func,
  onBlur: PropTypes.func,
  onImageClick: PropTypes.func,
  onAddClick: PropTypes.func,
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      navigation: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
  source: PropTypes.string,
  layout: PropTypes.string,
};

export default CSSModules(
  ProductsLayout, styles,
  { allowMultiple: true },
);

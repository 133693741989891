import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import Image from '../../../Image';

import { formColor } from '../../../../helper';
import styles from './styles.module.css';

class MeetTeamLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';
    const full = props.section.data[index].full === true ? 'Full' : '';

    this.state = {
      section: props.section,
      layout: props.section.data[index].layout,
      align,
      full,
    };

  }

  render() {

    const lay = this.state.layout === 'round' ? 'Round' : this.state.layout === 'square'
      ? 'Square' : this.state.layout === 'noGutters' ? 'NoGutters' : '';
    const teamTileWrapper = `teamTile${lay}Wrapper`;
    const teamImgWrapper = `teamImgWrapper${lay}`;
    const imgWrapper = `teamImg${lay}`;
    const teamInfoWrapper = `teamInfoWrapper${lay}`;
    const rowWrapper = `row${this.state.layout === 'noGutters' ? ' no-gutters' : ''}`;
    const spacing = this.state.layout === 'round' ? { padding: '20px 10px' } : this.state.layout === 'square'
      ? { marginLeft: '10px', marginRight: '10px', paddingBottom: '20px' } : undefined;

    const activeComponents = [];
    const components = [];
    this.state.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        activeComponents.push({ item, index });

      }

      return null;

    });

    activeComponents.map((item) => {

      let style;
      if (item.item.styles.backgroundColor.active) {

        let { backgroundColor } = item.item.styles;
        if (
          item.item.styles.backgroundColor.solid === '' &&
          item.item.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#ffffff' };

        }

        style = formColor(
          backgroundColor,
          false,
          item.item.styles.backgroundColor.opacity,
        );

      }

      const align = `align${item.item.align}`;
      const noGutters = this.state.layout === 'noGutters' && !item.item.data[0].active;

      const teamInfoElement = (
        <div
          className={styles[teamInfoWrapper]}
          style={
            this.state.layout === 'square' ? spacing
              : noGutters ? {
                display: 'flex', background: 'initial',
              } : undefined
          }
        >
          {
            item.item.data[1].active &&
            (
              <div className={`${styles.teamImageText} ${styles[`teamText${item.item.align}`]}`}>
                <h3>
                  { HTMLParser(item.item.data[1].data) }
                </h3>
              </div>
            )
          }
          {
            item.item.data[2].active &&
            (
              <div className={`${styles.teamImageText} ${styles[`teamText${item.item.align}`]}`}>
                <span>
                  { HTMLParser(item.item.data[2].data) }
                </span>
              </div>
            )
          }
        </div>
      );

      const newElement = (
        <div
          key={`${this.state.section._id}_layout_${item.index}`}
          className={`col-12 col-sm-6 col-lg-4 ${styles[teamTileWrapper]}`}
        >
          <div
            style={
              this.state.layout === 'round' && style ? { ...style, ...spacing }
                : noGutters ? { ...style, ...{ display: 'flex' } } : style
            }
            className={styles.tileContent}
          >
            {
              item.item.data[0].active &&
              (
                <div className={styles[teamImgWrapper]}>
                  {
                    this.state.layout === 'noGutters'
                    && (
                      <div className={styles.teamHoverNoGutters}>
                        {teamInfoElement}
                      </div>
                    )
                  }
                  <div className={styles[imgWrapper]}>
                    <Image
                      id={this.state.section.data[item.index].data[0].data.id}
                      url={this.state.section.data[item.index].data[0].data.src}
                      alt={this.state.section.data[item.index].data[0].data.alt}
                      imageClass={imgWrapper}
                      images={this.props.images}
                    />
                  </div>
                </div>
              )
            }
            {(this.state.layout !== 'noGutters' || noGutters) && teamInfoElement}
          </div>
        </div>
      );

      components.push(newElement);

      return null;

    });

    const headerLay = `teamHeader${this.state.layout === 'square' || this.state.layout === 'round' ? 'Alt' : this.state.layout === 'noGutters' ? 'NoGutters' : ''}`;
    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`teamContainer${this.state.full}`]}`}>
        <div className={`col-12 ${styles[headerLay]} ${styles[alignStyle]}`}>
          {
            this.state.section.data[0].active &&
            (
              HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
            )
          }
        </div>
        <div className={`${rowWrapper} ${styles[alignStyle]} ${styles.teamRowWrapper}`}>
          {components}
        </div>
      </div>
    );

  }

}

MeetTeamLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    }))
  }),
};

export default CSSModules(MeetTeamLayout, styles, { allowMultiple: true });
